<template>
  <div v-loading="loading">
    <div class="contentHead">
      <span>
        账单状态： 
        <template v-if="formInline.is_confirm==1">
          已确认
        </template>
        <span v-else class="red">待确认</span>
        <template v-if="formInline.is_income == 2">
          （请先提供账单，等待财务确认账单信息）
        </template>
      </span>
      <span class="ml20">
        开票状态：
        <span class="red" v-if="formInline.receipt_status == 1">部分开票</span>
        <span class="red" v-else-if="formInline.receipt_status == 2">全部开票</span>
        <span class="red" v-else-if="formInline.receipt_status == 0">待开票</span>
      </span>
      <el-button class="ml20" type="primary" v-if="formInline.is_confirm!=1 && formInline.is_income == 1" @click="twoConfrim(false)" size="small">全部确认</el-button>

      <span class="ml20" v-if="formInline.is_income != 2">（付款到账后可申请放单，需在委托详情页面操作）</span>
    </div>
    <div class="contentBox">
      <el-form :inline="true" label-position="left" :model="formInline" size="small" class="inline" label-width="120px" >
        <el-form-item label="账单号">
          <el-input v-model="formInline.bill_no" :readonly="readonlyBol"></el-input>
        </el-form-item>
        <el-form-item label="开票抬头">
          <el-tooltip effect="dark" :content="formInline.customer_invoice.title" placement="top">
            <el-input v-model="formInline.customer_invoice.title" :readonly="readonlyBol"></el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="纳税人地址" class="w100">
          <el-tooltip v-if="formInline.calculation_object.address_zh" effect="dark" :content="formInline.calculation_object.address_zh" placement="top">
            <el-input v-model="formInline.calculation_object.address_zh" :readonly="readonlyBol"></el-input>
          </el-tooltip>
          <el-input v-else v-model="formInline.calculation_object.address_zh" :readonly="readonlyBol"></el-input>
        </el-form-item>
        <el-form-item label="发票编号">
           <el-tooltip :disabled="!formInline.receipt_no" effect="dark" :content="formInline.receipt_no" placement="top">
            <el-input v-model="formInline.receipt_no" :readonly="readonlyBol"  ></el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="纳税人识别号">
          <el-input v-model="formInline.customer_invoice.itin" :readonly="readonlyBol"></el-input>
        </el-form-item>
        <el-form-item label="电话">
          <el-input v-model="formInline.customer_phone" :readonly="readonlyBol"  ></el-input>
        </el-form-item>
        <el-form-item label="开户银行">
          <el-input v-model="bankName" :readonly="readonlyBol"></el-input>
        </el-form-item>
        <el-form-item label="账号">
          <el-input v-model="bankNo" :readonly="readonlyBol"  ></el-input>
        </el-form-item>
        <el-form-item label="账单类型">
          <el-input v-model="formInline.is_income_zh" :readonly="readonlyBol"></el-input>
        </el-form-item>
        <el-form-item label="账单日期">
          <el-input v-model="formInline.bill_date" :readonly="readonlyBol"  ></el-input>
        </el-form-item>
        
      
        <el-form-item label="开票要求" class="w100">
          <el-input v-model="formInline.remarks" 
            type="textarea" :rows="4"
            :readonly="readonlyBol"  >
          </el-input>
        </el-form-item>
        
      </el-form>
      <div>
        <el-form label-position="top" class="formLabelAlign" size="mini" :inline="true" label-width="80px" 
          :style="{'height': formLabelAlign.length > 3 ?  '200px' :'auto'}">
          <div v-for="(item,index) in formLabelAlign" :key="item.currency_id">
            <template v-if="index == 0">
              <el-form-item label="原币种">
                <el-input v-model="item.currency" :readonly="readonlyBol"></el-input>
              </el-form-item>
              <el-form-item label="金额">
                <el-input v-model="item.include_tax" :readonly="readonlyBol"></el-input>
              </el-form-item>
              <el-form-item label="汇率">
                <el-input v-model="item.rate" :readonly="readonlyBol"></el-input>
              </el-form-item>
            </template>
            <template v-else>
              <el-form-item label="">
                <el-input v-model="item.currency" :readonly="readonlyBol"></el-input>
              </el-form-item>
              <el-form-item label="">
                <el-input v-model="item.include_tax" :readonly="readonlyBol"></el-input>
              </el-form-item>
              <el-form-item label="">
                <el-input v-model="item.rate" :readonly="readonlyBol"></el-input>
              </el-form-item>
            </template>
          </div>
        </el-form>
        <el-form label-width="120px" label-position="left" size="small" class="formLabelBottom">
          <el-form-item label="账单币种">
            <el-input v-model="formInline.currency_code" :readonly="readonlyBol"></el-input>
          </el-form-item>
          <el-form-item label="含税金额">
            <el-input v-model="formInline.include_amount" :readonly="readonlyBol"  ></el-input>
          </el-form-item>
          <el-form-item label="不含税金额">
            <el-input v-model="formInline.except_amount" :readonly="readonlyBol"  ></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="contentBottom">
      <el-table
        :data="tableData"
        style="width: 100%"
        :height="tableHeight"
        :border="true"
        v-tableHeight="{ bottomOffset: 74 }"
        :header-cell-style="{background:'#f5f7fa'}"
        :tableLoading="loading"
      >
        <el-table-column label="委托编号" :show-overflow-tooltip="true" width="150" >
          <template slot-scope="scope">{{scope.row.job_no | textFormat }}</template>
        </el-table-column>
        <el-table-column label="币种" :show-overflow-tooltip="true"     >
          <template slot-scope="scope">{{scope.row.code | textFormat }}</template>
        </el-table-column>
        <el-table-column label="费用名称" :show-overflow-tooltip="true" >
          <template slot-scope="scope">{{scope.row.fee_name | textFormat }}</template>
        </el-table-column>
        <el-table-column label="费用类型" :show-overflow-tooltip="true" >
          <template slot-scope="scope">{{scope.row.fba_fee_type==1?'客户付款':'平台退款'  }}</template>
        </el-table-column>
        <el-table-column label="单位" :show-overflow-tooltip="true"  >
          <template slot-scope="scope">{{scope.row.charge_unit_name_zh | textFormat }}</template>
        </el-table-column>
        <el-table-column label="数量" :show-overflow-tooltip="true" >
          <template slot-scope="scope">{{scope.row.quantity | textFormat }}</template>
        </el-table-column>
        <el-table-column label="单价" :show-overflow-tooltip="true" >
          <template slot-scope="scope">{{scope.row.unit_rate | textFormat }}</template>
        </el-table-column>
        <el-table-column label="含税价" :show-overflow-tooltip="true"     >
          <template slot-scope="scope">{{scope.row.include_tax | textFormat }}</template>
        </el-table-column>
        <el-table-column label="不含税价" :show-overflow-tooltip="true"     >
          <template slot-scope="scope">{{scope.row.except_tax | textFormat }}</template>
        </el-table-column>
        <el-table-column label="税率" :show-overflow-tooltip="true"     >
          <template slot-scope="scope">{{scope.row.val_rate | textFormat }}</template>
        </el-table-column>
        <el-table-column label="税额" :show-overflow-tooltip="true"     >
          <template slot-scope="scope">{{scope.row.val_amount | textFormat }}</template>
        </el-table-column>
        <el-table-column label="备注" :show-overflow-tooltip="true"     >
          <template slot-scope="scope">{{scope.row.remarks | textFormat }}</template>
        </el-table-column>
        <el-table-column label="操作">
          <div slot-scope="scope">
            <span v-if="scope.row.is_confirm == 1">已确认</span>
            <el-button type="text" v-else-if="formInline.is_income == 1" @click="twoConfrim(scope.row)">确认</el-button>
          </div>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { secondFormat } from "~/basePlugins/filters";
export default {
  name: "myBillDetail",
  data() {
    return {
      readonlyBol:true,
      tableData:[],
      formInline:{
        customer_invoice:{
          title:''
        },
        calculation_object:{
          address_zh:''
        }
      },
      formLabelAlign:[],
      tableHeight: 100,
      loading:false,
      bankName:"",
      bankNo:""
    };
  },
  mounted() {
    this.getHeader();
  },
  watch:{
  },
  computed: {},
  methods: {
    async getHeader() {
      this.loading = true;
      let params = {
        bill_id: this.$route.query.id,
      };
      let res = await Promise.all([
        this.$store.dispatch("baseConsole/my_bill_details", params),
      ]);
      if(res[0].success){
        let result = res[0].data;
        try {
          result.extend = JSON.parse(result.extend);
          result.is_income_zh = result.is_income==1?"客户付款":"平台退款";
          result.bill_date = secondFormat(result.bill_date,"LL");
          this.formLabelAlign = result.extend;
        } catch (error) {
          result.extend = {}
        }
        let customer_invoice = result.customer_invoice;
        let customer_bank = result.customer_bank;
        if(customer_invoice.bank && customer_bank){
          try {
            let bankOptions = JSON.parse(customer_invoice.bank)
            let defaultBank = bankOptions[customer_bank]
            if(defaultBank){
              this.bankName = defaultBank.bank_name;
              this.bankNo = defaultBank.bank_num;
            }
          } catch (error) {
            this.bankName = "";
            this.bankNo = "";
          }
        }
        this.tableData =  result.fba_fee_info;
        this.formInline = result;
      }
      this.loading = false;
    },
    twoConfrim(data){
      this.$confirm('费用确认后不可修改，如有异议，请联系销售人员，如无异议请确认！', "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
            this.confrim(data)
          })
        .catch(() => {});
    },
    async confrim(row){
      let params = {
        bill_id: this.formInline.id,
        user_id: this.USER_INFO.id
      };
      if(row){
        params.fba_fee_id	= row.fba_fee_id
      }else{
        let fba_fee_ids = this.tableData.filter(val =>{
          return val.is_confirm != 1 ;
        }).map(item=>{
          return item.fba_fee_id
        });
        params.fba_fee_id	= fba_fee_ids.join(',')
      }
      this.loading = true;
      let result = await this.$store.dispatch(
        "baseConsole/confirm_fba_fee",
        params
      );
      if (result && result.success) {
        this.loading = false;
        this.$message.success("操作成功");
        this.getHeader();
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped lang="less">
.contentHead{
  padding: 10px 15px 20px;
  font-weight: 600;
  color: #606266;
  .ml20{
    margin-left: 20px;
  }
  .red{
    color: #f35555;
  }
}
.contentBox, .contentBottom{
  background: #fff;
  padding: 15px;
  display: flex;
  justify-content: center;
  .inline{
    width: calc(90% - 500px);
    .el-form-item{
      width: 48%;
      /deep/.el-form-item__content{
        width: calc(80% - 150px) ;
      }
      /deep/.el-form-item__label{
        font-weight: 600;
      }
    }
    .w100{
      /deep/.el-form-item__content{
        width: calc(100% - 140px) ;
      }
    }
  }
  .formLabelAlign{
    width: 500px;
    height: 200px;
    padding-bottom: 10px;
    overflow-y: scroll;
    .el-form-item{
      width: 31%;
      /deep/.el-form-item__label{
        font-weight: 600;
      }
    }
  }
  .formLabelBottom{
    .el-form-item{
      /deep/.el-form-item__label{
        font-weight: 600;
      }
    }
  }
}
.contentBottom{
  margin-top: 10px;
}
</style>
